$green: rgb(194,210,24);
$greendark: rgb(148, 160, 19);
$greenextradark: rgb(85, 91, 11);
$greenlight: rgb(220, 234, 72);
$greenlightextra: rgb(229, 239, 118);
$blue: rgb(31,78,150);
$pink: rgb(198,54,99);
$grey: rgb(47,54,64);
$white: white;
$blue: rgb(31,78,150);
* {
  font-family: Roboto;
}
html  {
  height: 100%;
  box-sizing: border-box;
  font-size: 16px;
  scroll-behavior: smooth;
}
body {
  padding: 0;
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 500px;
  box-sizing: border-box;
}
@media only screen and (max-width: 768px) {
 .smallscreen {
   display: static;
 }
 .bigscreen {
   display: none !important;
 }
}
@media only screen and (min-width: 769px) and (max-width: 1200px) {
  .smallscreen {
    display: static;
  }
  .bigscreen {
    display: none !important;
  }
}
@media only screen and (min-width: 1201px) and (max-width: 1400px) {
  .smallscreen {
    display: none !important;
  }
  .bigscreen {
    display: static;
  }
}
@media only screen and (min-width: 1401px) and (max-width: 2000px) {
  .smallscreen {
    display: none !important;
  }
  .bigscreen {
    display: static;
  }
}
@media only screen and (min-width: 2001px) {
  .smallscreen {
    display: none !important;
  }
}
